.pagination-style-three a {
    margin: 5px;
    text-align: center;
    padding: 10px 15px 10px 15px;
    width: 40px;
    height: 40px;
    background: #ffffff; 
    border-radius: 100%; 
    box-shadow: 0px 5px 10px 0px rgba(0,0,0,.1);
}
.btnclear{
    margin: 5px;
    text-align: center;
    padding: 10px 15px 10px 15px;
    width: 40px;
    height: 40px;
    background: #ffffff; 
    border-radius: 10%; 
    box-shadow: 0px 5px 10px 0px rgba(0,0,0,.1);
}

.pagination-style-three a.selected, .pagination-style-three a:hover, .pagination-style-three a:active, .pagination-style-three a:focus { 
    background: #0dd19d; 
}